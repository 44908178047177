import { Controller } from "@hotwired/stimulus"
import { animateCSS } from "../animate.js"

// Connects to data-controller="modal"
export default class extends Controller {
  // connect() {
  //   const container = this.element
  //
  //   animateCSS(container, 'fadeInDown')
  //   container.showModal()
  // }
  //
  // close() {
  //   const container = this.element
  //
  //   animateCSS(container, 'fadeOutUp').then(() => {
  //     container.close()
  //   })
  // }

  connect() {
    const container = this.element

    animateCSS(container, 'fadeInDown')
    container.showModal()

    container.addEventListener('click', (event) => {
      if(event.target.nodeName === 'DIALOG') {
        this.close()
      }
    })
  }

  close() {
    const container = this.element

    animateCSS(container, 'fadeOutUp').then(() => {
      container.close()
    })
  }
}